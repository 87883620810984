h2 {
    font-size: 2rem;
}

.__content_time {
    display: flex;
    justify-content: space-between;
}

.__content_time .__text {
    width: 60%;
    min-width: 60%;
    padding: 1rem 0rem;
}

.__content_time .__text p {
    font-size: .9rem;
    line-height: 28px;
}

.__content_time .__images {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.__content_time .__images img {
    width: 60%;
    margin: auto;
}

.__btn_next {
    position: absolute;
    bottom: 30px;
    right: 30px;
    color: #FFFFFF;
    padding: .8rem 1rem;
    border-radius: 100px;
    background-color: var(--ribbon-700);
}


.__responses {
    width: 40%;
    display: flex;
    gap: 1rem;
    padding: 2rem 0rem;
    flex-direction: column;
}

.__responses .__response {
    display: flex;
    width: 100%;
    height: 50px;
    cursor: pointer;
    padding: 0rem 1rem;
    border-radius: 10px;
    align-items: center;
    color: var(--ribbon-700);
    background-color: var(--ribbon-100);
}

.__responses .__response_active {
    color: #FFFFFF;
    background-color: var(--ribbon-700);
}