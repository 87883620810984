.__box {
    width: 80%;
    margin: auto;
    height: inherit;
    display: flex;
    gap: 1rem;
}

.__box .__header {
    width: 100%;
    padding: 1rem 0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.__box .__header .__col1 {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.__box .__header .__col1 .__photo {
    width: 50px;
    height: 50px;
    min-width: 50px;
    overflow: hidden;
    border-radius: 100px;
    border: 2px solid #FFFFFF;
}

.__box .__header .__col1 .__photo img {
    width: 100%;
    height: 100%;
}

.__box .__header .__col1 .__name h3 {
    color: #FFFFFF;
}

.__box .__header .__col1 .__name p {
    color: #e3e3e3;
}

.__box .__header .__col2 {
    display: flex;gap: 1rem;
}
.__box .__header .__col2 p {
    color: #FFFFFF;
    font-weight: 500;
    padding: .8rem 1rem;
    border-radius: 100px;
    border: 2px solid #FFFFFF;
    background-color: var(--ribbon-900);
}

.__box .__timeline {
    position: relative;
    width: 100%;
    display: flex;
    padding: 2rem 0rem;
    align-items: center;
    justify-content: space-between;
}

.__box .__timeline .__line {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #FFFFFF;
}

.__box .__timeline .__time {
    display: grid;
    z-index: 1;
    width: 50px;
    height: 50px;
    place-items: center;
    border-radius: 100px;
    background-color: #FFFFFF;
}

.__box .__timeline .__time .__square_time {
    display: grid;
    width: 40px;
    height: 40px;
    place-items: center;
    border-radius: 100px;
}

.__box .__timeline .__time_active .__square_time {
    color: #FFFFFF;
    background-color: var(--ribbon-700);
}

.__box .__wrp_play {
    width: 100%;
    height: 500px;
    padding: 1rem 0rem;
}

.__box .__wrp_play .__play {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: #FFFFFF;
}

.__box .__wrp_play .__play .__btn {
    position: absolute;
    display: grid;
    top: 50%;
    width: 60px;
    height: 60px;
    place-items: center;
    border-radius: 100px;
    border: 4px solid #FFFFFF;
    background-color: var(--ribbon-700);
}

.__box .__wrp_play .__play .__btn .tabler-icon {
    stroke: #FFFFFF;
}

.__box .__wrp_play .__play .__content_play {
    width: 100%;
    height: inherit;
    padding: 1rem 40px;
}

.__box .__scores {
    width: 30%;
    height: 100%;
    padding: 1rem;
}

.__box .__scores .__scores_player {
    width: 100%;
    height: 100%;
    padding: 1rem;
    overflow-y: auto;
    border-radius: 20px;
    background-color: #FFFFFF;
}

.__box .__scores .__scores_player .__line {
    width: 100%;
    height: 1px;
    margin: 1rem 0rem;
    background-color: #888888;
}

.__box .__scores .__scores_player .__play {
    width: 100%;
    display: flex;
    padding: .8rem;
    border-radius: 10px;
    align-items: center;
    background-color: #F2F0F4;
    justify-content: space-between;
}

.__box .__scores .__scores_player .__play .__col1 {
    display: flex;
    gap: 1rem;
}

.__box .__scores .__scores_player .__play .__col1 .__avatar {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 100px;
    background-color: #e3e3e3;
}

.__box .__scores .__scores_player .__play .__col1 .__avatar img {
    width: 100%;
    height: 100%;
}

.__box .__scores .__scores_player .__play .__col1 .__name p {
    font-size: .8rem;
    font-weight: 500;
}
.__box .__scores .__scores_player .__play .__col2 p {
    padding: .4rem .8rem;
    font-size: .8rem;
    color: #FFFFFF;
    border-radius: 100px;
    background-color: var(--ribbon-700);
}