:root {

    --ribbon-50: #edf8ff;
    --ribbon-100: #d6eeff;
    --ribbon-200: #b5e3ff;
    --ribbon-300: #83d3ff;
    --ribbon-400: #48b9ff;
    --ribbon-500: #1e97ff;
    --ribbon-600: #0676ff;
    --ribbon-700: #0062ff;
    --ribbon-800: #084bc5;
    --ribbon-900: #0d439b;
    --ribbon-950: #0e2a5d;

    --font-family: "Poppins", sans-serif;
}

* {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    user-select: none;
    box-sizing: border-box;
    font-family: var(--font-family);
}

button {
    cursor: pointer;
}