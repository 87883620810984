.__app {
    position: relative;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    transition: .3s ease all;
    background-color: var(--ribbon-700);
}

.__alert {
    position: absolute;
    top: 50px;
    right: 100px;
    padding: .8rem 1rem;
    border-radius: 10px;
    background-color: #FFFFFF;
}

.__alert_error {
    color: rgb(212, 0, 0);
    border: 2px solid rgb(212, 0, 0);
}

.__alert_success {
    color: rgb(0, 212, 64);
    border: 2px solid rgb(0, 212, 64);
}

.__alert_warning {
    color: rgb(212, 208, 0);
    border: 2px solid rgb(212, 208, 0);
}

.__wrp {
    width: 100%;
    height: 100%;
}