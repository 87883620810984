.__wrp_home {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background-color: var(--ribbon-700);
}

.__wrp_home .__content {
    text-align: center;
}

.__wrp_home .__content .__title {
    color: #FFFFFF;
    text-align: center;
}

.__wrp_home .__content .__title .__p_1 {
    font-size: 1rem;
}

.__wrp_home .__content .__title .__p_2 {
    font-size: 3rem;
}

.__wrp_home .__content .__btn_start {
    position: relative;
    z-index: 1;
    width: 150px;
    height: 150px;
    margin: auto;
    color: #FFFFFF;
    font-size: 1.2rem;
    border-radius: 100px;
    background-color: var(--ribbon-800);
}

.__wrp_home .__content .__btn_start::before, 
.__wrp_home .__content .__btn_start::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: -1; /* Para que aparezca detrás del botón */
    box-shadow: 0 0 15px 5px var(--ribbon-900);
}

.__wrp_home .__content .__btn_start::before {
    box-shadow: 0 0 30px 15px var(--ribbon-700);
    filter: blur(5px); /* Añadir un ligero desenfoque para suavizar el aura */
    z-index: -2;
    opacity: 0.8;
}

.__wrp_home .__content .__btn_start::after {
    box-shadow: 0 0 50px 25px var(--ribbon-600);
    filter: blur(8px); /* Otro desenfoque para una segunda capa */
    opacity: 0.5;
}