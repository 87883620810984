.__screen {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.__title {
    color: #FFFFFF;
    font-size: 3rem;
}

.__p {
    font-size: 1rem;
    color: #FFFFFF;
    text-align: center;
}

.__form {
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 20px;
    background-color: #FFFFFF;
}

.__form .__form_group {
    width: 100%;
    margin-bottom: 1rem;
}

.__form .__form_flex {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.__form .__form_group .__label {
    display: block;
    font-weight: 500;
    margin-bottom: .8rem;
}

.__form .__form_group .__entry {
    width: 100%;
    height: 60px;
    padding: 0rem 1rem;
    border-radius: 10px;
    background-color: #F2F0F5;
}

.__form .__form_group .__form_control {
    width: 100%;
}

.__form .__form_group .__btn_save {
    width: 100%;
    height: 60px;
    color: #FFFFFF;
    border-radius: 10px;
    border: 2px solid var(--ribbon-900);
    background-color: var(--ribbon-700);
}